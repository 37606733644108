import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Main from "./pages/main";
import Provider from "./pages/provider";
import Privacy from "./pages/privacy";
import Terms from "./pages/term";
const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/provider" element={<Provider />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
