import React, { useEffect, useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

import header_img from "../assets/imgs/header.png";
import popSp from "../assets/imgs/button/pop-sp.png";
import User from "../assets/imgs/button/user.png";
import UserActive from "../assets/imgs/button/user_active.png";
import headerSp from "../assets/imgs/button/header-sp.png";
import headerMenu from "../assets/imgs/button/header-menu.png";
import closeBtn from "../assets/imgs/button/close.png";
import { useRouter } from "../router/hooks/index";
const Header = () => {
  const [showHeader, setShowHeader] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [status, setStatus] = useState(false);

  const router = useRouter();
  useEffect(() => {
    const handleScroll = () => {
      // Detect scroll position: show header when scrolled down
      if (window.scrollY > 100) {
        setShowHeader(true);
      } else {
        setShowHeader(false);
      }
    };

    // Add event listener to the window
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const goProvider = () => {
    router.push("/provider");
  };
  const goPrest = () => {
    window.location.href = "https://prestadores.medipet.pt";
  };
  const goSelf = () => {
    window.location.href = "https://selfcare.habit.io";
  };
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setStatus(true);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setStatus(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <div className={` ${showHeader ? "hidden " : "visible "}`}>
        <div className="hidden md:flex flex-row items-center self-center absolute right-[50px] top-[42px]">
          <button
            className={`font-14-700 mr-[25px] header-btn main-white`}
            onClick={() => goProvider()}
          >
            Rede de Prestadores
          </button>
          <button onClick={(e) => handlePopoverOpen(e)}>
            <img
              src={status ? UserActive : User}
              alt=""
              className=" header-btn max-w-[29px] max-h-[29px] "
            />
          </button>
        </div>
      </div>
      <div className={` ${showHeader ? "visible " : "hidden "}`}>
        <div className="hidden header md:flex flex-row justify-between md:pr-[50px]">
          <img
            src={header_img}
            alt=""
            className="max-w-[244px] max-h-[45px] w-full h-full ml-[91px] mt-[26px] "
          />
          <div className="flex flex-row items-center self-center ">
            <button
              className={`font-14-700 mr-[25px] header-btn main-white`}
              onClick={() => goProvider()}
            >
              Rede de Prestadores
            </button>
            <button onClick={(e) => handlePopoverOpen(e)}>
              <img
                src={status ? UserActive : User}
                alt=""
                className="max-w-[29px] max-h-[29px] header-btn"
              />
            </button>
          </div>
        </div>
        <div className="md:hidden header-sp flex flex-row justify-center items-center">
          <img
            src={headerSp}
            alt=""
            className="max-w-[57px] max-h-[57px] "
          ></img>
          <button
            className=" max-w-[24px] max-h-[13px] absolute right-[24px] header-btn"
            onClick={(e) => handlePopoverOpen(e)}
          >
            <img src={headerMenu} alt="" className="" />
          </button>
        </div>
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        disableScrollLock={true}
        sx={{
          marginTop: "15px",
          "& .MuiPaper-root": {
            border: "1px solid #fff",
            borderTopRightRadius: "0px",
            borderTopLeftRadius: "12px",
            borderBottomLeftRadius: "12px",
            borderBottomRightRadius: "12px",
            boxShadow: "0px 4px 4px 0px #697DA440",
            top: "75px !important",
            marginLeft: "-50px",
            "@media (max-width: 600px)": {
              maxWidth: "100%",
              marginTop: "-15px !important",
              left: "0px !important",
              right: "0px !important",
              top: "0px !important",
              borderTopLeftRadius: "0px",
              borderBottomLeftRadius: "18px",
              borderBottomRightRadius: "18px",
              marginLeft: "0px",
            },
          },
        }}
      >
        <Typography sx={{ p: "15px" }}>
          <div className="flex flex-col md:gap-1 main-grey gap-6 items-center md:items-start">
            <img src={popSp} alt="" className="md:hidden block" />
            <button
              className="md:hidden absolute top-3 right-3 "
              onClick={() => handlePopoverClose()}
            >
              {" "}
              <img src={closeBtn} alt=""></img>
            </button>
            <button
              className="font-12-500 md:hidden"
              onClick={() => goProvider()}
            >
              REDE DE PRESTADORES
            </button>
            {/* <button className="font-12-500" >Área de Cliente</button> */}
            <button className="font-12-500" onClick={() => goPrest()}>
              Área de Prestadores
            </button>
            <button className="font-12-500" onClick={() => goSelf()}>
              Área de Seguradora
            </button>
          </div>
        </Typography>
      </Popover>
    </>
  );
};

export default Header;
