import React, { createContext, useContext, useEffect } from "react";
import { loadMetaPixel } from "./helpers";
import { ANALYTICS_KEY } from "../../config";

const MetaPixelContext = createContext({
  trackEvent: () => {},
});

export const useMetaPixel = () => useContext(MetaPixelContext);

const MetaPixelProvider = ({ children }) => {
  useEffect(() => {
    loadMetaPixel(ANALYTICS_KEY);
  }, []);

  const trackEvent = (eventName, data) => {
    if (window.fbq) window.fbq("track", eventName, data);
    else console.warn("Meta Pixel not initialized yet");
  };

  return (
    <MetaPixelContext.Provider value={{ trackEvent }}>
      {children}
    </MetaPixelContext.Provider>
  );
};

export default MetaPixelProvider;
