export const FACEBOOK_EVENTS_URL =
  "https://connect.facebook.net/en_US/fbevents.js";

export const loadMetaPixel = async (pixelId) => {
  if (!window.fbq) {
    window.fbq = function () {
      if (window.fbq.callMethod)
        window.fbq.callMethod.apply(window.fbq, arguments);
      else window.fbq.queue.push(arguments);
    };
    window.fbq.queue = [];
    window.fbq.loaded = false;
    window.fbq.version = "2.0";
  }

  if (document.querySelector(`script[src="${FACEBOOK_EVENTS_URL}"]`)) return;

  const script = document.createElement("script");
  script.async = true;
  script.src = FACEBOOK_EVENTS_URL;

  script.onload = () => {
    window.fbq("init", pixelId);
    window.fbq("track", "PageView");
    window.fbq.loaded = true;
  };

  script.onerror = () => {
    console.error("Failed to load Meta Pixel Script");
  };

  document.head.appendChild(script);
  return script;
};
