import React from "react";
import Logo from "../assets/imgs/icon.png";
// import Help from '../assets/imgs/button/help.png';
import ICON from "../assets/imgs/footer.png";
import { useRouter } from "../router/hooks/index";
const Footer = () => {
  const router = useRouter();
  const onPrivacy = () => {
    router.push("/privacy");
  };
  const onTerms = () => {
    router.push("/terms");
  };
  return (
    <section className="flex flex-col">
      <div className="flex flex-col md:flex-row md:columns-3 md:footer footer-sp relative md:pl-[91px] md:pt-[70px] md:pb-[50px]">
        <img
          src={Logo}
          alt=""
          className="max-w-[76px] max-h-[76px] md:mr-[122px] mt-[-20px] md:mt-0 self-center md:self-start "
        />
        <div className="flex flex-col md:mr-[57px] ml-0 mt-[35px] md:mt-0 items-center md:items-start">
          <span className="font-14-700 main-blue mb-[23px]">
            Informações Legais
          </span>
          <button onClick={onTerms} className="font-14-500 main-blue mb-[18px]">
            Termos e Condições
          </button>
          <button
            onClick={onPrivacy}
            className="font-14-500 main-blue mb-[18px]"
          >
            Proteção de Dados Pessoais
          </button>
          {/* <a href="link" className="font-14-500 main-blue mb-[18px]">Políticas MediPet</a>
                    <a href="link" className="font-14-500 main-blue mb-[18px]">Código de Conduta</a>
                    <a href="link" className="font-14-500 main-blue mb-[18px]">Código Ético para Prestadores</a> */}
        </div>

        <div className="flex flex-col mt-[26px] md:mt-0 ml-0 mb-[93px] md:mb-0 items-center md:items-start ">
          <span className="font-14-700 main-blue mb-[20px]">Áreas</span>
          {/* <a href="https://prestadores@medipet.pt" className="font-14-500 main-blue mb-[18px]">Fale connosco</a>
                    <a href="https://registo.medipet.pt" className="font-14-500 main-blue mb-[18px]">Junte-se a nós</a> */}
          <a
            href="https://prestadores.medipet.pt"
            className="font-14-500 main-blue mb-[18px]"
          >
            Área de Prestadores
          </a>
          <a
            href="https://selfcare.habit.io"
            className="font-14-500 main-blue mb-[18px]"
          >
            {" "}
            Área de Seguradora
          </a>
        </div>

        <img
          src={ICON}
          alt=""
          className="md:absolute md:right-0 md:bottom-0 hidden md:block"
        />
        {/* <button onClick={toggleChat}>
                    <img src={Help} className="md:max-w-[94px] md:max-h-[94px] max-w-[54px] max-h-[54px] absolute bottom-[30px] right-[30px]" alt="" />
                </button> */}
      </div>
      <div className="bottom-bar flex md:flex-row flex-col justify-center items-center p-[20px]">
        <span className="font-13-300 mb-[10px] md:mb-0">
          © 2024 MediPet., Todos os direitos reservados
        </span>
        {/* <span className="font-13-500"> | <a href="">Termos de Uso </a>| <a href=""> Política de Privacidade</a></span> */}
      </div>
    </section>
  );
};

export default Footer;
