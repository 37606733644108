
export const PROXY_PATH="https://api.distributors.habit.io/medipet-website-prd"

export const INTRO='no'
export const OPTIONAL_COV='no'
export const PC_NAMES='no'
export const MAIN_COLOR="false"
export const SECONDARY_COLOR="false"
export const ANALYTICS_KEY="573436069192292"

export const PARTNER_NAME="false"

export const PARTNER_IMAGE="false"



export const CDN_BASE_URL="https://cdn.habit.io"

export const WHITELABEL_CONFIG_URL="https://cdn.habit.io/insurance-products/whitelabel-bios/config-files/custom-domains"

export const API_URL="https://platform.habit.io"

export const BASENAME="medipet-website-prd"
export const PAYMENT="false"
export const HABIT_TAG="'''yes'''"
